<template>
    <el-drawer v-model="isDrawerShow" :show-close="false" size="920px" @close="$emit('close')"
        :close-on-click-modal="true" ref="drawerRef" custom-class="rule-operate-drawer">
        <template #title>
            <div class="top-container">
                <span>自动规则</span>
                <el-icon @click="$emit('close')">
                    <i class="iconfont icon-ylclose"></i>
                </el-icon>
            </div>
        </template>
        <template #default>
            <el-form ref="formRef">
                <vxe-table :data="tableData" min-height="104" round>
                    <vxe-column field="name" title="广告系列名称" min-width="150px"></vxe-column>
                    <vxe-column field="spend" title="消耗">
                        <template #default="{ row }">
                            {{ row.spend !== 0 ? `$${base.thousands(row.spend)}` : row.spend }}
                        </template>
                    </vxe-column>
                    <vxe-column field="rule_switch" title="自动规则">
                        <template #default="{ row }">
                            <vxe-switch v-model="row.rule_switch" size="mini" open-label="打开" close-label="关闭"
                                @change="clearRule(row)"></vxe-switch>
                        </template>
                    </vxe-column>
                    <vxe-column field="rule" title="规则设置" min-width="250px">
                        <template #default="{ row, rowIndex }">
                            <div v-if="row.rule_switch">
                                <el-select v-model="row.rule_id" placeholder="请选择自动化规则" size="small"
                                    style="width: 100%;" filterable popper-class="ad-template-rule-select">
                                    <div class="add-rule" @click="goCustomRule(rowIndex)">
                                        <i class="iconfont icon-xinjian" />
                                        <span>新建规则</span>
                                    </div>
                                    <el-option v-for="item in options.rule" :key="item.id" :label="item.name"
                                        :value="item.id">
                                        <el-row type="flex" justify="space-between" align="middle">
                                            <span>{{ item.name }}</span>
                                            <el-tooltip effect="dark" content="去编辑" placement="top">
                                                <i class="iconfont icon-qubianji"
                                                    @click="goCustomRule(rowIndex, item.id)"
                                                    v-show="item.name !== '成本控制策略'" />
                                            </el-tooltip>
                                        </el-row>
                                    </el-option>
                                </el-select>
                                <div class="rule-description">
                                    <span class="tip" v-if="!row.rule_id">选择自动规则后，展示规则详情说明</span>
                                    <div v-else>
                                        <div class="rule-description-item">
                                            <span style="width: 100%;">
                                                <div class="name">
                                                    <span>
                                                        {{ options.rule.length > 0 && options.rule.filter(i => i.id ===
                                                            row.rule_id)[0]?.name +
                                                            "：" }}
                                                    </span>
                                                    <i class="iconfont icon-qubianji"
                                                        @click="goCustomRule(rowIndex, row.rule_id)"
                                                        v-show="options.rule.length > 0 && options.rule.filter(i => i.id === row.rule_id)[0].name !== '成本控制策略'" />
                                                </div>
                                                <div class="level">应用层级：{{ options.rule.length > 0 &&
                                                    options.rule.filter(i => i.id
                                                        === row.rule_id)[0].ads_level === '广告组' ? '广告系列中的广告组' : '广告系列' }}
                                                </div>
                                                <div v-html="options.rule.length > 0 && options.rule.filter(i => i.id ===
                                                    row.rule_id)[0].description" class="content"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else style="margin: 8px 0;">关闭规则</div>
                        </template>
                    </vxe-column>
                </vxe-table>
            </el-form>
            <div class="footer">
                <el-button @click="$emit('close')" size="small">取消</el-button>
                <el-button type="primary" size="small" @click="confirmClick">确定</el-button>
            </div>
        </template>
    </el-drawer>

    <!-- 自定义规则弹窗 -->
    <RuleDrawer ref="ruleDrawerRef" v-if="isRuleDrawerShow" @submit="handleRuleChange"
        @close="isRuleDrawerShow = false" />
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, nextTick } from 'vue'
import base from '@/base/filters/base'
import api from '@/request/api/ad'
import { ElLoading } from 'element-plus'
import RuleDrawer from '@/views/ads/adRule/RuleDrawer.vue'

const { proxy } = getCurrentInstance()
const props = defineProps({
    data: { type: Array, default: () => [] },
})

const tableData = ref([])
const options = ref({
    rule: []
})
const emit = defineEmits(['close', 'submit'])
const indexSelect = ref(0)
const isDrawerShow = ref(true)
const ruleDrawerRef = ref(null)
const isRuleDrawerShow = ref(false) // 是否显示自定义规则弹窗

const confirmClick = () => {
    let params = JSON.parse(JSON.stringify(tableData.value))
    params = params.map(item => {
        return {
            rule_id: item.rule_switch ? item.rule_id.toString() : '',
            campaign_id: item.id
        }
    })
    const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '正在提交修改中...'
    })
    const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
    api.series.updateRule(JSON.stringify(params), localStorage.getItem('AdTemplateAccountId'), user.fb_user_id).then(res => {
        if (res.code === 200) {
            proxy.$alert(`以下是规则修改情况:<br>${res.msg}`, '信息', {
                confirmButtonText: '收到',
                dangerouslyUseHTMLString: true,
                callback: (action) => {
                    emit('submit')
                }
            })
        }
    }).finally(() => {
        loading.close()
    })
}

/** 清空规则 */
const clearRule = (row) => {
    if (!row.rule_switch) {
        row.rule_id = ''
    }
}

/** 跳转新建规则和编辑规则 */
const goCustomRule = async (rowIndex, id) => {
    isRuleDrawerShow.value = true
    indexSelect.value = rowIndex
    await nextTick()
    if (id) {
        ruleDrawerRef.value.editRule(id)
    } else {
        ruleDrawerRef.value.addRule()
    }
}

/** 规则提交的回调 */
const handleRuleChange = (id) => {
    tableData.value[indexSelect.value].rule_id = id
    isRuleDrawerShow.value = false
    api.rule.getRuleList().then(res => {
        options.value.rule = res.rule
    })
}

onMounted(() => {
    tableData.value = JSON.parse(JSON.stringify(props.data))
    tableData.value.forEach(item => {
        item.rule_switch = item.rule_id ? true : false
    })
    api.rule.getRuleList().then(res => {
        options.value.rule = res.rule
    })
})
</script>


<style lang="less">
.rule-operate-drawer {
    .el-drawer__header {
        height: 64px;
        padding: 0;
        margin: 0;
        display: block;
    }

    .el-drawer__body {
        overflow-y: auto;
        padding: 24px 16px 64px 16px;
        margin: 0;

        .vxe-table {
            .vxe-cell {
                color: #262626;
                padding: 0 16px;
            }

            .vxe-header--row {
                color: #262626;
                background-color: #ffffff;
                font-family: 'PingFangSC-Medium, PingFang SC';
            }

            .vxe-header--column {
                padding: 9px 0;
            }
        }
    }
}

.ad-template-rule-select {
    .add-rule {
        height: 34px;
        padding: 0 20px;
        color: #2b82ff;
        font-size: 14px;
        cursor: pointer;
        line-height: 34px;

        i {
            margin-right: 8px;
        }
    }

    .el-select-dropdown__item {
        height: 34px;
        display: flex;
        padding: 0 20px 0 46px;
        flex-direction: column;

        &::after {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            width: 16px;
            height: 16px;
            border: 1px solid #d9d9d9;
            border-radius: 50%;
            -webkit-font-smoothing: antialiased;
        }

        &.selected {
            font-weight: 400;
            background: #F5F7FA !important;
            border-radius: 0 !important;
        }

        &.selected::after {
            content: '' !important;
            background: #2B82FF;
            box-shadow: inset 0 0 0 3px #ffffff;
            border: 1px solid #2b82ff;
        }
    }
}
</style>
<style lang="less" scoped>
.top-container {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    border-bottom: 1px solid #F0F0F0;

    span {
        font-size: 16px;
        font-family: PingFang SC-Medium;
        font-weight: 600;
        color: #262626;
        line-height: 24px;
    }

    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.rule-description {
    width: 100%;
    height: 118px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    overflow-y: auto;
    line-height: normal;
    padding: 6px 0;
    margin-top: 8px;
    margin-bottom: 8px;

    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-radius: 8px;
    }

    .rule-description-item {
        display: flex;
        margin: 0 8px 12px 8px;

        .name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #F4F4F5;
            border-radius: 4px;
            border: 1px solid #E9E9EB;
            padding: 0 8px;
            height: 24px;
            line-height: 24px;

            span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-weight: 500;
                color: #262626;
                font-family: PingFang SC-Regular;
                font-size: 14px;
                text-align: justified;
            }

            i {
                color: #8c8c8c;
                cursor: pointer;
            }
        }

        .level {
            font-family: PingFang SC-Regular;
            font-weight: 400;
            font-size: 14px;
            color: #262626;
            padding-left: 8px;
            margin-bottom: 4px;
        }

        .content {
            padding-left: 8px;
            margin-top: 2px;
            line-height: 1.5;
            white-space: normal;
        }
    }

    .tip {
        font-family: PingFang SC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #c0c4cc;
        margin-left: 14px;
    }
}

:deep(.vxe-switch) {
    padding: 0;

    .vxe-switch--button:focus {
        box-shadow: none !important;
    }
}

.footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 920px;
    height: 64px;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    padding: 16px 24px;
    justify-content: flex-end;
    background-color: #ffffff;
    z-index: 999;

    :deep(.el-button) {
        margin-left: 20px;
        font-family: PingFang SC-Medium;
        font-size: 14px;
    }
}
</style>